<template>
  <div class="mx-1">
    <div class="suitcase-header mt-4" v-if="suitcase_report">
      <div
        class="left-header-text"
        :style="{
          'background-color': facultyColor + '!important',
          color: $lightOrDark(facultyColor) ? 'black' : 'white',
        }"
      >
        <h1
          class="font-weight-bold text-center"
          :style="{ 'background-color': facultyColor }"
        >
          PDA
        </h1>
      </div>
      <h4 class="suitcase-faculty text-left">
        {{ suitcase_report.faculty_name }}
      </h4>
      <div class="logo-container">
        <h4 class="suitcase-title mr-2">
          MALETA <span class="suitcase-title-2">DIDÁCTICA</span>
        </h4>
        <div class="logo-section ml-3">
          <b-img class="institution-logo" :src="institution_logo"></b-img>
        </div>
      </div>
    </div>
    <!-- Encabezado principal con información de asignatura -->
    <div class="synthesis-header-container">
      <div
        class="empty-block"
        :style="{
          'background-color': suitcase_report
            ? facultyColor + '!important'
            : '',
        }"
      ></div>
      <h5 class="synthesis-header mb-0 py-2 text-white">
        <strong>SÍNTESIS PLAN DIDÁCTICO DE AULA</strong>
      </h5>
    </div>
    <b-table-simple
      class="page-break"
      v-if="suitcase_report"
      bordered
      responsive
    >
      <b-thead>
        <b-tr>
          <b-th
            class="second-header"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{ $getVisibleNames("manual.matter_code", false, "Código") }}</b-th
          >
          <b-th
            class="second-header"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >Nombre</b-th
          >
          <b-th
            class="second-header"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{
              $getVisibleNames("manual.credits", true, "Créditos SCT Totales")
            }}</b-th
          >
          <b-th
            class="second-header"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{
              $getVisibleNames(
                "manual.institutional_credits",
                true,
                "Créditos Institucionales"
              )
            }}</b-th
          >
          <b-th
            class="second-header"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{
              $getVisibleNames(
                "mesh.modality",
                false,
                "Modalidad de Asignaturas"
              )
            }}</b-th
          >
          <b-th
            class="second-header"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
          >
            {{
              $getVisibleNames(
                "mesh.formationline",
                false,
                "Línea de Formación"
              )
            }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td
            class="align-middle"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{ suitcase_report.matter_code }}</b-td
          >
          <b-td
            class="align-middle"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{ suitcase_report.matter_name }}</b-td
          >
          <b-td
            class="align-middle"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{ suitcase_report.sct_credits }}</b-td
          >
          <b-td
            class="align-middle"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{
              suitcase_report.duoc_credits ? suitcase_report.duoc_credits : "-"
            }}</b-td
          >
          <b-td
            class="align-middle"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
          >
            <template v-if="suitcase_report.modalities.length > 0">
              <div
                v-for="modality in suitcase_report.modalities"
                :key="modality.id"
                :variant="$lightOrDark(facultyColor) ? 'dark' : 'light'"
                class="mr-1"
              >
                {{ modality.name }}
              </div>
            </template>
            <template v-else>
              <div>N/A</div>
            </template></b-td
          >
          <b-td
            class="align-middle"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
          >
            {{ suitcase_report.formation_line }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <!-- *********************** SECCIÓN DE INFORMACIÓN GENERAL *********************** -->

    <b-table-simple
      class="page-break"
      v-if="suitcase_report"
      bordered
      responsive
    >
      <b-thead>
        <b-tr>
          <b-th colspan="9" class="first-header text-left"
            ><span class="general-background-icon mr-2">
              <b-icon icon="list-check"></b-icon>
            </span>
            1. INFORMACIÓN GENERAL
          </b-th>
        </b-tr>
        <b-tr>
          <b-th
            v-if="suitcase_report.matter_is_transversal == false"
            class="second-header"
            >CARRERA/S</b-th
          >
          <b-th class="second-header">PRERREQUISITOS</b-th>
          <!-- <b-th class="second-header">LINEA FORMATIVA</b-th> -->
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td
            v-if="suitcase_report.matter_is_transversal == false"
            class="text-left"
          >
            <template
              v-if="
                suitcase_report.other_careers &&
                suitcase_report.other_careers.length > 0
              "
            >
              <!-- :style="{
                    'background-color':
                      facultyColor + '!important',
                    color: $lightOrDark(facultyColor)
                      ? 'black'
                      : 'white',
                  }" -->
              <span>
                {{
                  suitcase_report.other_careers
                    .map((career) => career.egress_profile__career__name)
                    .join(", ")
                }}
              </span>
            </template>

            <template v-else>
              <div>N/A</div>
            </template>
          </b-td>
          <b-td class="text-left">
            <template
              v-if="
                suitcase_report.prerequisites &&
                suitcase_report.prerequisites.length > 0
              "
            >
              <span>
                {{
                  suitcase_report.prerequisites
                    .map((prerequisite) => prerequisite.matter__name)
                    .join(", ")
                }}
              </span>
            </template>
            <template v-else>
              <div>N/A</div>
            </template>
          </b-td>
          <!-- Linea Formativa -->
          <!-- <b-td>{{ suitcase_report.formation_line }}</b-td> -->
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <!-- *********************** SECCIÓN OTRAS INDICACIONES ***********************-->
    <template v-if="suitcase_report">
      <div class="suitcase-matter-description mt-2">
        <div class="matter-description-header text-left">
          <span class="general-background-icon mr-2">
            <b-icon icon="gear"></b-icon>
          </span>
          2. DESCRIPCIÓN DE LA ASIGNATURA
        </div>
        <div class="matter-description-body">
          <template v-if="suitcase_report.matter_description">
            <div
              class="rich-text-content"
              v-html="suitcase_report.matter_description"
            ></div>
          </template>
          <template v-else>
            <div>No hay descripción para la asignatura.</div>
          </template>
        </div>
      </div>
    </template>

    <!-- *********************** SECCIÓN TABLA DE COMPETENCIAS ***********************-->

    <b-table-simple
      v-if="suitcase_report && suitcase_report.competence_dict_list.length"
      class="mt-2 mb-2 page-break"
      bordered
      responsive
    >
      <template v-if="suitcase_report.competence_dict_list.length > 0">
        <b-tr>
          <b-th class="suitcase-competences-th text-justify">
            Capacidad/es Institucionales
          </b-th>
          <!-- Capacidades Institucionales -->
          <b-td class="suitcase-competences-td text-justify">
            <div v-if="suitcase_report.capacities.length == 0">
              No hay Capacidad/es Institucionales para la Asignatura.
            </div>
            <div v-else>
              <div>
                {{ suitcase_report.capacities.join(", ") }}
              </div>
              <!-- <div
              v-for="(capacity, index) in suitcase_report.capacities"
              :key="index"
            >
              {{ capacity }}
            </div> -->
            </div>
          </b-td>
        </b-tr>
        <template
          v-for="competence_dict_list in suitcase_report.competence_dict_list"
        >
          <template
            v-for="(competence, index) in competence_dict_list.competences"
          >
            <b-tr
              :key="
                competence_dict_list.egress_profile_name + '-' + competence.id
              "
            >
              <b-th
                :rowspan="competence_dict_list.competences.length"
                v-if="index == 0"
                class="suitcase-competences-th text-justify"
              >
                Capacidades y Competencias del Programa
                <template
                  v-if="suitcase_report.competence_dict_list.length > 1"
                >
                  de "{{ competence_dict_list.egress_profile_name }}"
                </template>
              </b-th>
              <b-td class="suitcase-competences-td text-justify">
                <span v-if="competence.order">{{
                  "C" + competence.order + "."
                }}</span>
                {{ competence.full_sentence }}</b-td
              >
            </b-tr>
          </template>
        </template>
      </template>
      <!-- <template
        v-for="(competence, index) in suitcase_report.linked_competences"
      >
        <b-tr :key="competence.id">
          <b-th
            :rowspan="suitcase_report.linked_competences.length"
            v-if="index == 0"
            class="suitcase-competences-th"
          >
            {{ $getVisibleNames("mesh.competence", true, "Competencias") }}
            {{ egressProfileLinked ? 'de "' + egressProfileLinked.name : "" }}
          </b-th>
          <b-td class="suitcase-competences-td">
            <span v-if="competence.order">{{
              "C" + competence.order + "."
            }}</span>
            {{ competence.full_sentence }}</b-td
          >
        </b-tr>
      </template> -->
    </b-table-simple>

    <!-- *********************** SECCIÓN TABLA DE RESULTADOS DE APRENDIZAJE ***********************-->

    <b-table-simple
      v-if="suitcase_report && suitcase_report.learning_results.length"
      bordered
      responsive
      class="mt-2 page-break"
    >
      <b-thead>
        <b-th class="second-header">RESULTADOS DE APRENDIZAJE</b-th>
        <b-th class="second-header">INDICADOR DE LOGRO</b-th>
      </b-thead>
      <template
        v-for="(learning_result, index1) in suitcase_report.learning_results"
      >
        <template v-if="learning_result.evaluation_criterias_micro.length > 0">
          <b-tr
            v-for="(
              ec_micro, index2
            ) in learning_result.evaluation_criterias_micro"
            :key="'learning-result-' + index1 + 'ec-micro-' + index2"
          >
            <b-td
              class="suitcase-learning-results-td text-justify"
              :rowspan="learning_result.evaluation_criterias_micro.length"
              v-if="index2 == 0"
            >
              <span v-if="learning_result.order">{{
                "RA" + learning_result.order + "."
              }}</span>
              {{ learning_result.full_sentence }}</b-td
            >
            <b-td class="suitcase-ec-micro-td text-justify"
              >{{ "IL " + learning_result.order + "." + (index2 + 1)
              }}<span class="ml-2">{{ ec_micro.full_sentence }}</span>
            </b-td>
          </b-tr>
        </template>
        <template v-else>
          <b-tr :key="'learning-result-' + index1">
            <b-td class="suitcase-learning-results-td"
              ><span v-if="learning_result.order">{{
                "RA" + learning_result.order + "."
              }}</span>
              {{ learning_result.full_sentence }}</b-td
            >
            <b-td>N/A</b-td>
          </b-tr>
        </template>
      </template>
    </b-table-simple>

    <!-- *********************** SÍNTESIS RUTA DE APRENDIZAJE ***********************-->
    <b-table-simple
      class="page-break"
      v-if="suitcase_report"
      bordered
      responsive
    >
      <b-thead>
        <b-tr>
          <b-th colspan="6" class="first-header text-left"
            ><span class="general-background-icon mr-2">
              <b-icon icon="clipboard-data"></b-icon>
            </span>
            3. SÍNTESIS DE LA RUTA DE APRENDIZAJE
          </b-th>
        </b-tr>
        <b-tr>
          <b-th class="thirth-header">RESULTADO DE APRENDIZAJE</b-th>
          <b-th class="thirth-header"> INDICADOR DE LOGRO</b-th>
          <b-th class="thirth-header"> EXPERIENCIA DE APRENDIZAJE</b-th>
          <b-th class="thirth-header"> ACTIVIDADES Y EVALUACIONES </b-th>
          <b-th class="thirth-header"> AMBIENTES DE APRENDIZAJE </b-th>
          <b-th class="thirth-header"> N° HORAS </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template
          v-for="(
            learning_result, index1
          ) in suitcase_report.learning_experiences"
        >
          <template
            v-if="
              learning_result.activities.length > 0 &&
              learning_result.evaluations.length > 0
            "
          >
            <template v-for="(activity, index2) in learning_result.activities">
              <b-tr :key="'learning-result' + index1 + 'activity' + index2">
                <b-td
                  class="text-left align-middle"
                  v-if="index2 == 0"
                  :rowspan="
                    learning_result.activities.length +
                    learning_result.evaluations.length
                  "
                >
                  <!-- RESULTADO DE APRENDIZAJE -->
                  <template v-if="learning_result.study_units.length > 0">
                    <b-badge
                      v-for="study_unit in learning_result.study_units"
                      :key="study_unit.id"
                      variant="none"
                      pill
                      class="ra-badge mr-1 noprint"
                      v-b-tooltip.v-secondary.noninteractive.bottom="
                        study_unit.full_sentence
                      "
                    >
                      RA{{ study_unit.order }}
                    </b-badge>
                    <p
                      class="learning-result-badge1"
                      :key="'p-lr1' + study_unit.id"
                      v-for="study_unit in learning_result.study_units"
                    >
                      {{ study_unit.full_sentence }}
                    </p>
                  </template>
                  <template v-else>
                    <div>N/A</div>
                  </template>
                </b-td>
                <b-td class="text-left align-middle">
                  <!-- INDICADOR DE LOGRO -->
                  <b-badge
                    v-for="evaluation_criteria in activity.evaluation_criterias_micro"
                    :key="evaluation_criteria.id"
                    variant="none"
                    pill
                    class="il-badge mr-1"
                    v-b-tooltip.v-secondary.noninteractive.bottom="
                      evaluation_criteria.full_sentence
                    "
                  >
                    {{
                      "IL" +
                      evaluation_criteria.study_unit__order +
                      "." +
                      evaluation_criteria.order
                    }}
                  </b-badge>
                  <!-- <p
                    class="evaluation-criteria-badge-activity"
                    v-for="evaluation_criteria in activity.evaluation_criterias_micro"
                    :key="'p-ec1' + evaluation_criteria.id"
                  >
                    {{ evaluation_criteria.full_sentence }}
                  </p> -->
                  <!-- <p
                    v-for="evaluation_criteria in activity.evaluation_criterias_micro"
                    :key="evaluation_criteria.id"
                  >
                    <span v-if="evaluation_criteria.study_unit__order"
                      >{{
                        "IL" +
                        evaluation_criteria.study_unit__order +
                        "." +
                        evaluation_criteria.order
                      }}
                    </span>
                    <span>{{ evaluation_criteria.full_sentence }}</span>
                  </p> -->
                </b-td>
                <b-td
                  class="text-left align-middle"
                  v-if="index2 == 0"
                  :rowspan="
                    learning_result.activities.length +
                    learning_result.evaluations.length
                  "
                >
                  <!--  EXPERIENCIA DE APRENDIZAJE -->
                  <!-- <span v-if="learning_result.order">{{
                    "EA " + learning_result.order
                  }}</span>
                  - -->
                  {{ learning_result.name }}
                </b-td>
                <b-td class="text-left">
                  <!-- ACTIVIDADES Y EVALUACIONES -->
                  <!-- Act {{ index1 + 1 }}.{{ index2 + 1 }} - -->
                  {{ activity.name }}
                </b-td>
                <b-td
                  :class="[
                    activity.study_environments.length > 0
                      ? 'text-left'
                      : 'text-ceter',
                  ]"
                >
                  <!-- AMBIENTES DE APRENDIZAJE -->
                  <template v-if="activity.study_environments.length > 0">
                    <span>
                      {{
                        activity.study_environments
                          .map((x) => x.name)
                          .join(", ")
                      }}
                    </span>
                  </template>
                  <template v-else>
                    <div class="text-left">
                      <span>N/A </span>
                    </div>
                  </template>
                </b-td>
                <b-td class="text-center">
                  <!-- N° HORAS -->
                  {{ activity.suggested_hours }}
                </b-td>
              </b-tr>
            </template>
            <template
              v-for="(evaluation, index3) in learning_result.evaluations"
            >
              <b-tr :key="'learning-result' + index1 + 'evaluation' + index3">
                <b-td class="text-left align-middle">
                  <!-- INDICADOR DE LOGRO -->
                  <!-- <p
                    v-for="evaluation_criteria in evaluation.evaluation_criterias_micro"
                    :key="evaluation_criteria.id"
                  >
                    <span v-if="evaluation_criteria.study_unit__order"
                      >{{
                        "IL" +
                        evaluation_criteria.study_unit__order +
                        "." +
                        evaluation_criteria.order
                      }}
                    </span>
                    <span>{{ evaluation_criteria.full_sentence }}</span>
                  </p> -->
                  <b-badge
                    v-for="evaluation_criteria in evaluation.evaluation_criterias_micro"
                    :key="evaluation_criteria.id"
                    variant="none"
                    pill
                    class="il-badge mr-1"
                    v-b-tooltip.v-secondary.noninteractive.bottom="
                      evaluation_criteria.full_sentence
                    "
                  >
                    {{
                      "IL" +
                      evaluation_criteria.study_unit__order +
                      "." +
                      evaluation_criteria.order
                    }}
                  </b-badge>
                  <!-- <p
                    class="evaluation-criteria-badge-evaluation"
                    v-for="evaluation_criteria in evaluation.evaluation_criterias_micro"
                    :key="'p-ec2' + evaluation_criteria.id"
                  >
                    {{ evaluation_criteria.full_sentence }}
                  </p> -->
                </b-td>
                <b-td class="text-left">
                  <!-- ACTIVIDADES Y EVALUACIONES -->
                  <!-- Ev {{ evaluation.type_name }} {{ index3 + 1 }} - -->
                  {{ evaluation.name }}
                </b-td>

                <b-td
                  :class="[
                    evaluation.study_environments.length > 0
                      ? 'text-left'
                      : 'text-ceter',
                  ]"
                >
                  <!-- AMBIENTES DE APRENDIZAJE -->
                  <template v-if="evaluation.study_environments.length > 0">
                    <span>
                      {{
                        evaluation.study_environments
                          .map((x) => x.name)
                          .join(", ")
                      }}
                    </span>
                  </template>
                  <template v-else>
                    <div class="text-left">
                      <span>N/A </span>
                    </div>
                  </template>
                </b-td>
                <b-td class="text-center">
                  <!-- N° HORAS -->
                  {{ evaluation.suggested_hours }}
                </b-td>
              </b-tr>
            </template>
          </template>
          <template v-else-if="learning_result.activities.length > 0">
            <template v-for="(activity, index2) in learning_result.activities">
              <b-tr :key="'learning-result' + index1 + 'activity' + index2">
                <b-td
                  class="text-left align-middle"
                  v-if="index2 == 0"
                  :rowspan="
                    learning_result.activities.length +
                    learning_result.evaluations.length
                  "
                >
                  <!-- RESULTADO DE APRENDIZAJE -->
                  <template v-if="learning_result.study_units.length > 0">
                    <b-badge
                      v-for="study_unit in learning_result.study_units"
                      :key="study_unit.id"
                      variant="none"
                      pill
                      class="ra-badge mr-1 noprint"
                      v-b-tooltip.v-secondary.noninteractive.bottom="
                        study_unit.full_sentence
                      "
                    >
                      RA{{ study_unit.order }}
                    </b-badge>
                    <p
                      class="learning-result-badge2"
                      v-for="study_unit in learning_result.study_units"
                      :key="'p-lr2' + study_unit.id"
                    >
                      {{ study_unit.full_sentence }}
                    </p>
                  </template>
                  <template v-else>
                    <div>N/A</div>
                  </template>
                </b-td>
                <b-td class="text-left align-middle">
                  <!-- INDICADOR DE LOGRO -->
                  <!-- <p
                    v-for="evaluation_criteria in activity.evaluation_criterias_micro"
                    :key="evaluation_criteria.id"
                  >
                    <span v-if="evaluation_criteria.study_unit__order"
                      >{{
                        "IL" +
                        evaluation_criteria.study_unit__order +
                        "." +
                        evaluation_criteria.order
                      }}
                    </span>
                    <span>{{ evaluation_criteria.full_sentence }}</span>
                  </p> -->
                  <b-badge
                    v-for="evaluation_criteria in activity.evaluation_criterias_micro"
                    :key="evaluation_criteria.id"
                    variant="none"
                    pill
                    class="il-badge mr-1"
                    v-b-tooltip.v-secondary.noninteractive.bottom="
                      evaluation_criteria.full_sentence
                    "
                  >
                    {{
                      "IL" +
                      evaluation_criteria.study_unit__order +
                      "." +
                      evaluation_criteria.order
                    }}
                  </b-badge>
                  <!-- <p
                    class="evaluation-criteria-badge-activity2"
                    v-for="evaluation_criteria in activity.evaluation_criterias_micro"
                    :key="'p-ec3' + evaluation_criteria.id"
                  >
                    {{ evaluation_criteria.full_sentence }}
                  </p> -->
                </b-td>
                <b-td
                  class="text-left align-middle"
                  v-if="index2 == 0"
                  :rowspan="
                    learning_result.activities.length +
                    learning_result.evaluations.length
                  "
                >
                  <!--  EXPERIENCIA DE APRENDIZAJE -->
                  <!-- <span v-if="learning_result.order">{{
                    "EA " + learning_result.order
                  }}</span>
                  - -->
                  {{ learning_result.name }}
                </b-td>
                <b-td class="text-left">
                  <!-- ACTIVIDADES Y EVALUACIONES -->
                  <!-- Act {{ index1 + 1 }}.{{ index2 + 1 }} - -->
                  {{ activity.name }}
                </b-td>
                <b-td
                  :class="[
                    activity.study_environments.length > 0
                      ? 'text-left'
                      : 'text-ceter',
                  ]"
                >
                  <!-- AMBIENTES DE APRENDIZAJE -->
                  <template v-if="activity.study_environments.length > 0">
                    <span>
                      {{
                        activity.study_environments
                          .map((x) => x.name)
                          .join(", ")
                      }}
                    </span>
                  </template>
                  <template v-else>
                    <div class="text-left">
                      <span>N/A </span>
                    </div>
                  </template>
                </b-td>
                <b-td class="text-center">
                  <!-- N° HORAS -->
                  {{ activity.suggested_hours }}
                </b-td>
              </b-tr>
            </template>
          </template>
          <template v-else-if="learning_result.evaluations.length > 0">
            <template
              v-for="(evaluation, index3) in learning_result.evaluations"
            >
              <b-tr :key="'learning-result' + index1 + 'evaluation' + index3">
                <b-td
                  class="text-left align-middle"
                  v-if="index3 == 0"
                  :rowspan="
                    learning_result.activities.length +
                    learning_result.evaluations.length
                  "
                >
                  <!-- RESULTADO DE APRENDIZAJE -->
                  <template v-if="learning_result.study_units.length > 0">
                    <b-badge
                      v-for="study_unit in learning_result.study_units"
                      :key="study_unit.id"
                      variant="none"
                      pill
                      class="ra-badge mr-1 noprint"
                      v-b-tooltip.v-secondary.noninteractive.bottom="
                        study_unit.full_sentence
                      "
                    >
                      RA{{ study_unit.order }}
                    </b-badge>
                    <p
                      class="learning-result-badge3"
                      v-for="study_unit in learning_result.study_units"
                      :key="'p-lr3' + study_unit.id"
                    >
                      {{ study_unit.full_sentence }}
                    </p>
                  </template>
                  <template v-else>
                    <div>N/A</div>
                  </template>
                </b-td>
                <b-td class="text-left align-middle">
                  <!-- INDICADOR DE LOGRO -->
                  <!-- <p
                    v-for="evaluation_criteria in evaluation.evaluation_criterias_micro"
                    :key="evaluation_criteria.id"
                  >
                    <span v-if="evaluation_criteria.study_unit__order"
                      >{{
                        "IL" +
                        evaluation_criteria.study_unit__order +
                        "." +
                        evaluation_criteria.order
                      }}
                    </span>
                    <span>{{ evaluation_criteria.full_sentence }}</span>
                  </p> -->
                  <b-badge
                    v-for="evaluation_criteria in evaluation.evaluation_criterias_micro"
                    :key="evaluation_criteria.id"
                    variant="none"
                    pill
                    class="il-badge mr-1"
                    v-b-tooltip.v-secondary.noninteractive.bottom="
                      evaluation_criteria.full_sentence
                    "
                  >
                    {{
                      "IL" +
                      evaluation_criteria.study_unit__order +
                      "." +
                      evaluation_criteria.order
                    }}
                  </b-badge>
                  <!-- <p
                    class="evaluation-criteria-badge-evaluation2"
                    v-for="evaluation_criteria in evaluation.evaluation_criterias_micro"
                    :key="'p-ec4' + evaluation_criteria.id"
                  >
                    {{ evaluation_criteria.full_sentence }}
                  </p> -->
                </b-td>
                <b-td
                  class="text-left align-middle"
                  v-if="index3 == 0"
                  :rowspan="
                    learning_result.activities.length +
                    learning_result.evaluations.length
                  "
                >
                  <!--  EXPERIENCIA DE APRENDIZAJE -->
                  <!-- <span v-if="learning_result.order">{{
                    "EA " + learning_result.order
                  }}</span>
                  - -->
                  {{ learning_result.name }}
                </b-td>
                <b-td class="text-left">
                  <!-- ACTIVIDADES Y EVALUACIONES -->
                  <!-- Ev {{ evaluation.type_name }} {{ index3 + 1 }} - -->
                  {{ evaluation.name }}
                </b-td>
                <b-td
                  :class="[
                    evaluation.study_environments.length > 0
                      ? 'text-left'
                      : 'text-ceter',
                  ]"
                >
                  <!-- AMBIENTES DE APRENDIZAJE -->
                  <template v-if="evaluation.study_environments.length > 0">
                    <span>
                      {{
                        evaluation.study_environments
                          .map((x) => x.name)
                          .join(", ")
                      }}
                    </span>
                  </template>
                  <template v-else>
                    <div class="text-left">
                      <span>N/A </span>
                    </div>
                  </template>
                </b-td>
                <b-td class="text-center">
                  <!-- N° HORAS -->
                  {{ evaluation.suggested_hours }}
                </b-td>
              </b-tr>
            </template>
          </template>
        </template>
        <template
          v-if="
            suitcase_report.transversal_exams &&
            suitcase_report.transversal_exams.length > 0
          "
        >
          <template
            v-for="transversal_exam in suitcase_report.transversal_exams"
          >
            <b-tr :key="transversal_exam.id">
              <b-td colspan="3">
                <!-- Examen
                {{ transversal_exam.type_name }} -->
              </b-td>
              <b-td class="text-left">{{ transversal_exam.name }}</b-td>
              <b-td
                :class="[
                  transversal_exam.study_environments.length > 0
                    ? 'text-left'
                    : 'text-ceter',
                ]"
                ><template
                  v-if="transversal_exam.study_environments.length > 0"
                >
                  <div
                    v-for="(
                      environment, index
                    ) in transversal_exam.study_environments"
                    :key="environment.id"
                    variant="info"
                    class="mr-1"
                  >
                    {{ index > 0 ? ", " : "" }}{{ environment.name }}
                  </div>
                </template></b-td
              >
              <b-td>{{ transversal_exam.suggested_hours }}</b-td>
            </b-tr>
          </template>
        </template>
        <template
          v-if="
            suitcase_report.learning_experiences.length > 0 ||
            suitcase_report.transversal_exams.length > 0
          "
        >
          <b-tr>
            <b-td colspan="5"> TOTAL</b-td>
            <b-td>{{
              suitcase_report.total_egress_profile_matter_evaluation_hours
            }}</b-td>
          </b-tr>
        </template>
        <template
          v-if="
            suitcase_report.learning_results.length == 0 &&
            suitcase_report.transversal_exams.length == 0
          "
        >
          <b-td class="text-center" colspan="6"
            >No hay Síntesis de la Ruta de Aprendizaje.</b-td
          >
        </template>
      </b-tbody>
    </b-table-simple>
    <!-- *********************** SECCIÓN DE EVALUACIONES PARCIALES  ***********************-->

    <b-table-simple
      class="page-break"
      v-if="suitcase_report"
      bordered
      responsive
    >
      <b-thead>
        <b-tr>
          <b-th colspan="5" class="first-header text-left"
            ><span class="general-background-icon mr-2">
              <b-icon icon="star"></b-icon>
            </span>
            4. EVALUACIONES
          </b-th>
        </b-tr>
        <b-tr>
          <b-th class="second-header">EVALUACIÓN</b-th>
          <b-th class="second-header"> SITUACIÓN EVALUATIVA</b-th>
          <b-th class="second-header"> DESCRIPCIÓN </b-th>
          <b-th class="second-header"> PESO DE LA EVALUACIÓN PARCIAL (%)</b-th>
          <b-th class="second-header"> PONDERACIÓN FINAL EVALUACIONES/ET</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template
          v-if="
            suitcase_report.partial_evaluations &&
            suitcase_report.partial_evaluations.length > 0
          "
        >
          <b-tr
            v-for="(evaluation, index) in suitcase_report.partial_evaluations"
            :key="evaluation.id"
          >
            <b-td class="text-left">{{ evaluation.name }}</b-td>
            <b-td class="text-left">
              {{
                evaluation.evaluation_situations.length == 0
                  ? "N/A"
                  : evaluation.evaluation_situations.join(", ")
              }}
            </b-td>
            <b-td class="text-left">
              <div
                v-html="evaluation.description"
                class="rich-text-content"
              ></div>
            </b-td>
            <b-td>{{ evaluation.weighing | Round }}%</b-td>
            <b-td
              class="text-center align-middle"
              v-if="index == 0"
              :rowspan="suitcase_report.partial_evaluations.length"
              >{{ getTotalWeighing + "%" }}
            </b-td>
          </b-tr>
          <template
            v-if="
              suitcase_report.transversal_exams &&
              suitcase_report.transversal_exams.length > 0
            "
          >
            <template
              v-for="transversal_exam in suitcase_report.transversal_exams"
            >
              <b-tr :key="transversal_exam.id">
                <b-td class="text-left">{{ transversal_exam.name }}</b-td>
                <b-td class="text-left">
                  {{
                    transversal_exam.evaluation_situations.length > 0
                      ? transversal_exam.evaluation_situations.join(", ")
                      : "N/A"
                  }}
                </b-td>
                <b-td class="text-left">
                  <div
                    v-html="transversal_exam.description"
                    class="rich-text-content"
                  ></div>
                </b-td>
                <b-td>-</b-td>
                <b-td>{{ transversal_exam.weighing + "%" }}</b-td>
              </b-tr>
            </template>
          </template>
          <template>
            <b-tr>
              <b-td colspan="4"> TOTAL</b-td>
              <b-td>{{ getAllTotalWeighing }}%</b-td>
            </b-tr>
          </template>
        </template>
        <template v-else>
          <b-tr>
            <b-td class="text-center" colspan="5"
              >No hay Evaluaciones Parciales.</b-td
            >
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>

    <!-- *********************** EXPERIENCIAS DE APRENDIZAJE  ***********************-->
    <template
      v-if="suitcase_report && suitcase_report.learning_experiences.length > 0"
    >
      <b-table-simple
        class="page-break"
        v-if="suitcase_report"
        bordered
        responsive
      >
        <b-thead>
          <b-tr>
            <b-th colspan="8" class="first-header text-left"
              ><span class="general-background-icon mr-2">
                <b-icon icon="handbag"></b-icon>
              </span>
              5. EXPERIENCIAS DE APRENDIZAJE
            </b-th>
          </b-tr></b-thead
        ></b-table-simple
      >
      <template v-for="learning_result in suitcase_report.learning_experiences">
        <b-table-simple
          class="page-break"
          bordered
          responsive
          :key="learning_result.id"
        >
          <b-thead>
            <b-tr>
              <b-th
                colspan="1"
                class="learning-result-order text-center"
                :style="{
                  'background-color': facultyColor + '!important',
                  color: $lightOrDark(facultyColor) ? 'black' : 'white',
                }"
              >
                {{ learning_result.order }}
              </b-th>
              <b-th colspan="7" class="first-header">
                <div class="learning-experiences-header-drop">
                  <span v-if="learning_result.order">{{
                    "EA " + learning_result.order
                  }}</span>
                  -
                  {{ learning_result.name }}
                </div>
              </b-th>
            </b-tr>
            <b-tr>
              <b-th colspan="8" class="second-header"> DESCRIPCIÓN </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td colspan="8">
                <div
                  v-html="learning_result.description"
                  class="rich-text-content"
                ></div>
              </b-td>
            </b-tr> </b-tbody
        ></b-table-simple>
        <b-table-simple
          class="page-break"
          bordered
          responsive
          :key="'activity_' + learning_result.id"
        >
          <b-thead>
            <b-tr>
              <b-th colspan="5" class="first-header"
                ><span class="general-background-icon mr-2">
                  <b-icon icon="pencil"></b-icon>
                </span>
                ACTIVIDADES
              </b-th>
            </b-tr>
            <b-tr>
              <b-th class="second-header"> ACTIVIDADES </b-th>
              <!-- Se tienen que crear por institución en los support-resource-type 
              RECURSOS DE APRENDIZAJE y TECNOLOGÍA EDUCATIVA-->
              <b-th class="second-header"> RECURSOS DE APRENDIZAJE </b-th>
              <b-th class="second-header"> TECNOLOGÍA EDUCATIVA </b-th>
              <b-th class="second-header"> AMBIENTE DE APRENDIZAJE</b-th>
              <b-th class="second-header"> N° HORAS</b-th>
            </b-tr></b-thead
          >
          <b-tbody>
            <b-tr
              v-for="activity in learning_result.activities"
              :key="'activity_' + activity.id + learning_result.id"
            >
              <b-td class="text-left">{{ activity.name }}</b-td>
              <b-td>
                <template v-if="activity.learning_resources.length > 0">
                  <p
                    v-for="learning_resource in activity.learning_resources"
                    :key="'learning_resource_' + learning_resource.id"
                    variant="info"
                    class="mr-1 rich-text-content"
                    v-html="learning_resource.name"
                  ></p>
                </template>
                <template v-else>N/A</template>
              </b-td>
              <b-td>
                <template v-if="activity.educational_technology.length > 0">
                  <p
                    v-for="educational in activity.educational_technology"
                    :key="'educational_' + educational.id"
                    variant="info"
                    class="mr-1 rich-text-content"
                    v-html="educational.name"
                  ></p>
                </template>
                <template v-else>N/A</template>
              </b-td>
              <b-td>
                <template v-if="activity.study_environments.length > 0">
                  <!-- <b-badge
                    v-for="environment in activity.study_environments"
                    :key="'environment_' + environment.id"
                    variant="info"
                    class="mr-1"
                  >
                    {{ environment.name }}
                  </b-badge> -->
                  <div
                    v-for="environment in activity.study_environments"
                    :key="'environment_' + environment.id"
                    variant="info"
                    class="mr-1 text-left"
                  >
                    {{ environment.name }}
                  </div>
                </template>
                <template v-else>N/A</template>
              </b-td>
              <b-td>
                {{ activity.suggested_hours }}
              </b-td>
            </b-tr>
            <b-tr v-if="learning_result.activities.length == 0">
              <b-td colspan="5">
                No hay actividades en esta Experiencia de Aprendizaje.
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-table-simple
          class="page-break"
          bordered
          responsive
          :key="'evaluation_' + learning_result.id"
        >
          <b-thead>
            <b-tr>
              <b-th colspan="3" class="first-header"
                ><span class="general-background-icon mr-2">
                  <b-icon icon="star"></b-icon>
                </span>
                EVALUACIONES
              </b-th>
            </b-tr>
            <b-tr>
              <b-th class="second-header"> TIPO DE EVALUACIÓN </b-th>
              <b-th class="second-header"> SITUACIÓN EVALUATIVA </b-th>
              <b-th class="second-header"> DESCRIPCIÓN </b-th>
            </b-tr></b-thead
          >
          <b-tbody>
            <b-tr
              v-for="evaluation in learning_result.evaluations"
              :key="evaluation.id + learning_result.id"
            >
              <td class="text-left">{{ evaluation.name }}</td>
              <td>
                {{
                  evaluation.evaluation_situations.length == 0
                    ? "N/A"
                    : evaluation.evaluation_situations.join(", ")
                }}
              </td>
              <td>
                <div
                  v-if="evaluation.description"
                  v-html="evaluation.description"
                  class="rich-text-content"
                ></div>
                <div v-else>Sin Descripción</div>
              </td>
            </b-tr>
            <b-tr v-if="learning_result.evaluations.length == 0">
              <b-td colspan="3">
                No hay evaluaciones en esta Experiencia de Aprendizaje.
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </template>
    </template>
    <EvaluationCustomFab
      class="noprint"
      :matter_id="egress_profile_matter_id"
      :show_matter_program="true"
    ></EvaluationCustomFab>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "LearningSuitcaseTeacherView",
  components: {
    EvaluationCustomFab: () =>
      import("@/components/evaluations/EvaluationCustomFab"),
  },
  data() {
    return {
      egress_profile_matter_id: Number(
        this.$route.params.egress_profile_matter_id
      ),
      // egress_profile_linkage: null,
      suitcase_report: null,
      default_color: "var(--primary-font-color)",
      default_bg_color: "#f8f9fa",
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      institution_logo: "getInstitutionLogo",
      images: names.IMAGES,
      egressProfileMatters: names.EGRESS_PROFILES_MATTERS,
      egressProfiles: names.EGRESS_PROFILES,
    }),
    egressProfileMatter() {
      if (!this.egress_profile_matter_id) return null;
      return this.egressProfileMatters.find(
        (x) => x.id == this.egress_profile_matter_id
      );
    },
    egressProfile() {
      if (!this.egressProfileMatters) return null;
      return this.egressProfiles.find(
        (x) => x.id == this.egressProfileMatter.egress_profile
      );
    },
    facultyColor() {
      if (this.suitcase_report) {
        if (this.suitcase_report.matter_is_transversal) return "black";
        else return this.suitcase_report.faculty_color;
      }
      return "black";
    },
    // egressProfileLinked() {
    //   if (!this.egress_profile_linkage) return null;
    //   return this.egressProfiles.find(
    //     (x) => x.id == this.egress_profile_linkage.linked_egress_profile
    //   );
    // },
    suitcaseLearningResults() {
      // if (!this.suitcase_report.learning_results) return;
      let list = this.suitcase_report.learning_results;
      return list.sort((a, b) => a.order - b.order);
    },
    getTotalWeighing() {
      let total_weighing = 0;
      let total_weighing_sixty = 0;
      if (this.suitcase_report) {
        this.suitcase_report.partial_evaluations.forEach((x) => {
          total_weighing = total_weighing + x.weighing;
        });
        total_weighing_sixty = total_weighing * 0.6;
      }
      return total_weighing_sixty;
    },
    getAllTotalWeighing() {
      let total_partial = this.getTotalWeighing;
      let total_transversal = 0;
      let total_evaluations = 0;

      if (this.suitcase_report) {
        this.suitcase_report.transversal_exams.forEach((x) => {
          total_transversal = total_transversal + x.weighing;
        });
        total_evaluations = total_partial + total_transversal;
      }
      return total_evaluations;
    },
  },
  methods: {
    fetchSuitcaseReport() {
      if (this.egress_profile_matter_id) {
        this.$restful
          .Get(
            `/teaching/learning_suitcase/?egress_profile_matter=${this.egress_profile_matter_id}&learning_suitcase_option=6`
          )
          .then((response) => {
            this.suitcase_report = response;
          });
      }
    },
  },
  beforeDestroy() {
    this.$store.commit(names.RESET_TITLE_OF_THE_PRINT);
  },
  mounted() {
    this.fetchSuitcaseReport();
    if (this.egress_profile_matter_id)
      this.$store
        .dispatch(
          names.FETCH_EGRESS_PROFILE_MATTER,
          this.egress_profile_matter_id
        )
        .then((egress_profile_matter) => {
          this.$store
            .dispatch(names.FETCH_MATTER, egress_profile_matter.matter)
            .then((matter) => {
              this.$store.commit(
                names.MUTATE_TITLE_OF_THE_PRINT,
                `PDA - ${matter.code} ${matter.name}`
              );
            });
          // this.$restful
          //   .Get(
          //     `/mesh/egress-profile-linkage/?linker_egress_profile=${egress_profile_matter.egress_profile}`
          //   )
          //   .then((response) => {
          //     if (response[0]) {
          //       this.egress_profile_linkage = response[0];
          //       this.$store.dispatch(
          //         names.FETCH_EGRESS_PROFILE,
          //         response[0].linked_egress_profile
          //       );
          //     }
          //   });
          this.$store.dispatch(
            names.FETCH_EGRESS_PROFILE,
            egress_profile_matter.egress_profile
          );
        });
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.ra-badge,
.il-badge {
  border: 1px solid black !important;
  font-size: 9pt;
  letter-spacing: 0.3px;
}
.learning-result-badge1,
.learning-result-badge2,
.learning-result-badge3,
.evaluation-criteria-badge-activity,
.evaluation-criteria-badge-activity2,
.evaluation-criteria-badge-evaluation,
.evaluation-criteria-badge-evaluation2 {
  display: none;
}
.suitcase-header {
  display: flex;
  position: relative;
  height: 56px;
  align-items: center;
}
.suitcase-faculty {
  width: 100%;
  margin-left: 1%;
  margin-right: 1em;
}
.suitcase-title {
  max-width: 100px;
  vertical-align: top;
  font-weight: bold;
  letter-spacing: 2.5px !important;
  line-height: 0.9;
}
.suitcase-title > .suitcase-title-2 {
  letter-spacing: -2px !important;
  font-weight: 100 !important;
}
.logo-container {
  display: flex;
  margin-left: auto;
  margin-right: 5em;
}
.logo-section {
  border-left: 1px solid;
  padding-left: 1em;
}
.logo-section > .institution-logo {
  max-height: 50px;
  transform: translateY(-25%);
  z-index: 1;
}
.left-header-text {
  width: 20%;
  margin-left: 1px;
}
.synthesis-header-container {
  display: flex;
  margin-left: 1px;
}
.empty-block {
  width: 5%;
}
.synthesis-header {
  background-color: black !important;
  width: 95%;
  margin-right: 1px;
  z-index: 10;
}
.first-header {
  background-color: #808080 !important;
  color: white;
}
.second-header {
  vertical-align: middle;
  background-color: #d9d9d9 !important;
}
.third-header {
  background-color: #bfbfbf !important;
}
.general-background-icon {
  padding: 0.3em;
  border-radius: 30px;
  border: 1px dotted;
}
.pre-requisites-tag {
  font-size: var(--secondary-font-size);
}
.suitcase-program-indications {
  border: 1px solid #dee2e6;
}
.program-indications-header {
  background: #808080 !important;
  color: white;
  border-bottom: 1px solid #dee2e6;
  font-weight: bold;
  padding: 0.7em;
}
.program-indications-body {
  padding: 1em;
}
.suitcase-matter-description {
  border: 1px solid #dee2e6;
}
.matter-description-header {
  padding: 0.7em;
  background-color: #808080 !important;
  color: white;
  font-weight: bold;
}
.matter-description-body {
  padding: 1em;
}
.suitcase-competences-th {
  vertical-align: middle;
  background-color: #808080 !important;
  color: white;
  min-width: 15em;
}
.suitcase-competences-td {
  text-align: left;
}
.suitcase-learning-results-th {
  vertical-align: middle;
  background-color: #808080 !important;
  color: white;
  min-width: 15em;
}
.suitcase-learning-results-td,
.suitcase-ec-micro-td {
  text-align: left;
}
.suitcase-learning-results-td {
  vertical-align: middle;
}
.learning-results-synthesis-th {
  background-color: #808080 !important;
  color: white;
}
.teaching-profile-header {
  vertical-align: middle;
  background-color: #d9d9d9 !important;
}
.assistant-header {
  padding: 0.7em;
  background-color: #808080 !important;
  color: white;
  font-weight: bold;
}
.assistant-container {
  display: flex;
  border: 1px solid #dee2e6;
}
.assistant-drop-header {
  background-color: #d9d9d9 !important;
  font-weight: bold;
}
.assistant-content {
  padding: 1em;
}
.biblio-header {
  vertical-align: middle;
  background-color: #d9d9d9 !important;
}
.learning-result-order {
  width: 10%;
}
.learning-experiences-header-drop {
  margin-left: -10%;
}
@media print {
  .badge {
    border: none;
  }
  .learning-result-badge1,
  .learning-result-badge2,
  .learning-result-badge3,
  .evaluation-criteria-badge-activity,
  .evaluation-criteria-badge-activity2,
  .evaluation-criteria-badge-evaluation,
  .evaluation-criteria-badge-evaluation2 {
    display: flex;
    text-align: justify;
  }
}
.page-break {
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}
</style>